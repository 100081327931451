<template>

  <b-card>
    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      primary-key="MonatJahr"
      :fields="fields"
      :sort-compare="compDate"
      @row-selected="selectRow"
    >
      <template #cell(Status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(AbrechnungID)="data">
        <b-link
          size="sm"
          variant="empty"
          @click="download(data.item)"
        >
          Herunterladen
        </b-link>
      </template>
      <template #cell(Total)="data">
        <div class="text-right">
          {{ data.value }}
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Pro Seite"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BLink, BCard, BBadge, BTable, BFormGroup, BFormSelect, BPagination, BCardBody,
} from 'bootstrap-vue'
import db from '@/service/abrechnung'

export default {
  components: {
    BLink,
    BCard,
    BBadge,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardBody,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      currRow: null,
      file: null,
      maxFileSize: 500000,
      fields: [
        { key: 'JahrMonat', label: 'Jahr Monat' },
        { key: 'Status', label: 'Status' },
        { key: 'AbrechnungID', label: 'Abrechnung' },
        { key: 'Total', label: 'Total', thClass: 'text-right' },
      ],
      status: [{
        erstellt: 'Erstellt', versand: 'Versandt', bezahlt: 'Bezahlt', erinnert: 'Zahlungserinnerung', gemahnt: 'Mahnung',
      },
      {
        erstellt: '', versand: '', bezahlt: 'light-success', erinnert: 'light-warning', gemahnt: 'light-warning',
      }],
      items: null,
      anzahlBelegeTotal: 0,
    }
  },
  async created() {
    const r = await db.getAbrechnungMandant(this.$g.user.MandantID)
    if (r.data.length > 0) {
      this.items = r.data
      this.totalRows = this.items.length
    }
  },
  methods: {
    async selectRow(row) {
      this.currRow = row[0]
    },
    async download(row) {
      const r = await db.getAbrechnungDatei(row.AbrechnungID, 'abrechnung')
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', 'Abrechnung.pdf')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    compDate(itemA, itemB, key) {
      if (key !== 'JahrMonat') return false
      return itemA.Datum - itemB.Datum
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
